const loadLanguage = async (lang) => {
  switch (lang) {
    case 'ja':
      return {
          main: await import("./main.ja.json"),
          arcana: await import("./arcana.ja.json")
      }
    case 'zh':
      return {
        main: await import("./main.zh.json"),
        arcana: await import("./arcana.zh.json")
    }
    case 'en':
    default:
      return {
        main: await import("./main.en.json"),
        arcana: await import("./arcana.en.json")
    }
  }
}
const $ = (key: string) => document.querySelector(key)
const main = async () => {
  const s = new URLSearchParams(window.location.search)
  const l = await loadLanguage(s.get('la'))

  // ローカライゼーション
  $('#__title').innerHTML = `<h1 class="header">${l.main.title}<span>${l.main.subtitle}</span></h1>`
  $('#__language').innerText = l.main.language

  if ($('#index')) {
    // ローカライゼーション
    $('#__reading').innerText = l.main.text.reading
    $('#__heroTarotReading').innerText = l.main.text.heroTarotReading
    $('#__heroTarotDescription').innerText = l.main.text.heroTarotDescription
    $('#__readingOneCard').innerText = l.main.text.readingOneCard
  }
  if ($('#one')) {
    // カードの裏面をクリックしたら開く
    $('#__card-back').onclick = () => {
      $('#__card-front').classList.add('is-open')
      $('#__card-back').classList.add('is-open')
      setResult(l)
    }
    // ローカライゼーション
    $('#__cardOpenCard').innerText = l.main.text.cardOpenCard
    // $('#__cocoaAdvice').innerText = l.main.text.cocoaAdvice
  }
}

// カードをセット
const setResult = (l) => {
  const number = Math.floor(Math.random() * 22)
  $('#__card-front').innerHTML = `<img src="/images/arcana/${number}.jpg" width="200px"/>`
  $('#__name').innerText = l.arcana[number].name
  $('#__description').innerText = l.arcana[number].description
  // $('#__advice').innerText = l.arcana[number].advice
}

main()